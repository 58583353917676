<template>
  <vue-element-loading :active="!appReady" is-full-screen :text="`${randomLoadingText} ....`"></vue-element-loading>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppLoader",
  data() {
    return {
      loadingText: [
        "Hang tight, we're loading something great...",
        "Almost there... Your app is ready!",
        "Loading your experience, just a moment...",
        "Fetching your data, please wait a sec!",
        "Good things come to those who wait... almost done!",
      ],
    };
  },
  computed: {
    randomLoadingText() {
      return this.loadingText[Math.floor(Math.random() * this.loadingText.length)];
    },
    appReady() {
      return !!this.user;
    },
    ...mapState("auth", ["user"]),
  },
};
</script>
