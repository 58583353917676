<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="navMenuItems" />
  </div>
</template>

<script>
import navMenuItems from "@/navigation/horizontal";
import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue";

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>

<style scoped>
.navbar-container.main-menu-content {
  /* A light, neutral gradient background that complements bright blue */
  background: linear-gradient(135deg, #f0f4f8, #e2e8f0);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
</style>
