<template>
  <div>
    <app-loader></app-loader>
    <layout-horizontal v-if="user">
      <router-view />
    </layout-horizontal>
  </div>
</template>

<script>
import LayoutHorizontal from "@core/layouts/layout-horizontal/LayoutHorizontal.vue";
import AppLoader from "@/components/AppLoader.vue";
import { mapState } from "vuex";

export default {
  components: {
    LayoutHorizontal,
    AppLoader,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(`auth`, [`user`]),
  },
};
</script>
