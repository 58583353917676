export default [
  {
    title: "Dashboard",
    icon: "MonitorIcon",
    route: "dashboard",
    action: "manage",
    resource: "dashboard",
  },
  {
    title: "Nawala Checker",
    route: "nawala-list",
    icon: "RssIcon",
    action: "read",
    resource: "nawala",
  },
  {
    title: "Clients",
    icon: "StarIcon",
    route: "client-list",
    action: "read",
    resource: "client",
  },
  {
    title: "User Accounts",
    icon: "UsersIcon",
    route: "useraccount-list",
    action: "read",
    resource: "useraccount",
  },
  {
    title: "Config",
    icon: "SettingsIcon",
    route: "config",
    action: "manage",
    resource: "config",
  },
];
